<template>
  <div class="grid">
    <Dialog
      :visible="visible"
      :header="exibirHeader()"
      :closeOnEscape="!saving && !confirmDialog"
      :closable="!saving"
      :modal="true"
      :containerStyle="{ width: '70vw' }"
      class="p-fluid"
      @show="onShow()"
      @hide="onHide()"
      @update:visible="fechar()">
      <BlockUI :blocked="saving">
        <div class="col-12 md:col-12">
          <div class="card">
            <Toast />
            <form @submit.prevent="validate()">
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-6">
                  <label for="chave" class="required">Chave</label>
                  <InputText
                    id="chave"
                    v-model="configuracao.chave"
                    :class="{
                      'p-invalid': submitted && v$.configuracao.chave.$invalid,
                    }"
                    type="text"
                    :disabled="isEdit" />
                  <div
                    v-if="submitted && v$.configuracao.chave.required.$invalid"
                    class="p-error">
                    O campo Chave é obrigatório.
                  </div>
                </div>
                <div class="field col-12 md:col-6">
                  <label for="valor" class="required">Valor</label>
                  <InputText
                    id="valor"
                    v-model="configuracao.valor"
                    :class="{
                      'p-invalid': submitted && v$.configuracao.valor.$invalid,
                    }"
                    type="text" />
                  <div
                    v-if="submitted && v$.configuracao.valor.required.$invalid"
                    class="p-error">
                    O campo Valor é obrigatório.
                  </div>
                </div>
              </div>
              <div class="p-fluid formgrid grid">
                <div class="field col-12">
                  <label for="descricao" class="required">Descrição</label>
                  <InputText
                    id="descricao"
                    v-model="configuracao.descricao"
                    :class="{
                      'p-invalid':
                        submitted && v$.configuracao.descricao.$invalid,
                    }"
                    type="text" />
                  <div
                    v-if="
                      submitted && v$.configuracao.descricao.required.$invalid
                    "
                    class="p-error">
                    O campo Descrição é obrigatório.
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </BlockUI>
      <template #footer>
        <Button
          :disabled="saving"
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-text"
          @click="fechar()" />
        <Button :disabled="saving" autofocus @click="validate">
          <span v-if="!saving" class="pi pi-check"></span>
          <span v-if="saving" class="pi pi-spin pi-spinner"></span>
          <span v-if="!saving" class="ml-2">Salvar</span>
          <span v-if="saving" class="ml-2">Aguarde</span>
        </Button>
      </template>
    </Dialog>
  </div>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Configuracao from '@/domain/Configuracao.js'
import ConfiguracaoService from '@/service/ConfiguracaoService.js'

export default {
  props: {
    visible: { type: Boolean, required: true },
    update: { type: Boolean, required: true },
    propsconfiguracao: {
      type: Object,
      required: false,
      default: new Configuracao(),
    },
  },
  setup() {
    return { v$: UseVuelidate() }
  },

  data() {
    return {
      configuracao: new Configuracao(),
      id: this.$route.params.id,
      submitted: false,
      submitStatus: null,
      isEdit: false,
      saving: false,
      confirmDialog: false,
    }
  },

  validations() {
    return {
      configuracao: {
        chave: { required },
        valor: { required },
        descricao: { required },
      },
    }
  },

  created() {
    this.service = new ConfiguracaoService(this.$http)
    if (this.id) {
      this.service.getConfiguracao(this.id).then((res) => {
        this.configuracao = res
      })
      this.isEdit = true
    } else this.isEdit = false
  },

  methods: {
    exibirHeader() {
      return this.update ? 'Editar Configuração' : 'Nova Configuração'
    },

    fechar() {
      this.$emit('update:visible', false)
    },

    onShow() {
      this.configuracao = JSON.parse(JSON.stringify(this.propsconfiguracao))
    },

    onHide() {},

    validate() {
      this.submitted = true
      this.v$.configuracao.$touch()

      if (this.v$.configuracao.$invalid) {
        this.submitStatus = 'error'
        return
      } else {
        this.submitStatus = 'PENDING'
        this.salvar()
        setTimeout(() => {
          this.submitStatus = 'OK'
        }, 500)
      }
    },

    salvar() {
      this.saving = true
      this.service
        .saveConfiguracao(this.configuracao)
        .then(
          () => {
            if (this.isEdit) {
              this.exibeToast('update')
            } else {
              this.exibeToast('save')
            }
          },
          (err) => {
            if (this.isEdit) {
              this.exibeToast('update-error', err.response.data.message)
            } else {
              this.exibeToast('save-error', err.response.data.message)
            }
          },
        )
        .finally(() => {
          this.saving = false
          this.$emit('carregarDados')
          this.fechar()
        })
    },

    exibeToast(tipo, msg) {
      if (tipo === 'save') {
        this.$toast.add({
          severity: 'success',
          summary: 'Configuração salva com sucesso!',
          life: 10000,
        })
      } else if (tipo === 'save-error') {
        this.$toast.add({
          severity: 'error',
          summary: msg,
          life: 10000,
        })
      } else if (tipo === 'update') {
        this.$toast.add({
          severity: 'success',
          summary: 'Configuração Atualizada com sucesso!',
          life: 10000,
        })
      } else if (tipo === 'update-error') {
        this.$toast.add({
          severity: 'error',
          summary: msg,
          life: 10000,
        })
      }
    },
  },
}
</script>

<style scoped>
.required:after {
  content: ' *';
  color: red;
}
</style>
